import { ChatSocketService } from './modules/tickets/services/chat-socket.service';
import { Component, Inject, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './modules/authentication/services/auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TicketsService } from './modules/tickets/services/tickets.service';
import { ExchangeDirectionService } from './modules/exchange-direction/services/exchange-direction.service';
import { UploadImageService } from './modules/user/services/upload-image.service';
import { CountdownTime } from './modules/authentication/interfaces/confirm-code';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [AuthService, TicketsService, ExchangeDirectionService, ChatSocketService, UploadImageService, MessageService]
})
export class AppComponent implements OnDestroy {

  public isLoading!: boolean; // if app is loading

  // timer count down
  public timerIsOn: boolean = true;
  public totalSeconds:number = 900;
  public countdown: CountdownTime = { minutes: 0, seconds: 900 };

  private isBrowser: boolean;
  private _AuthService = inject(AuthService)
  private _MessageService = inject(MessageService);
  private _CookiesService = inject(SsrCookieService);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }



  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this._AuthService.validateUser(); // get userinfo by token
      this.validateUser();
      // refresh user info using new token
      // if (this._CookiesService.check('tokenUser')) {
      //   this._AuthService.validateUser(); // get userinfo by token
      // }
    } else {
      // Add any server-specific logic here if needed
    }
  }

  validateUser() {
    // refresh user Token
    setInterval(() => {
      this.refreshUserTokenInApp()
    }, 900000);
  }

  refreshUserTokenInApp() {
    // refresh tokenUser After 15 minutes
    this._AuthService.freshTheToken(); // and also refresh userinfo
    // update the user info and show alert
    if (this._CookiesService.check('tokenUser')) {
      this._MessageService.add({ severity: 'warn', summary: 'Session Expired', detail: "Your Session Is Expired And Refreshed" });
    }
  }

  ngOnDestroy(): void {

  }
}
