import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

export const twofaAuthGuard: CanActivateFn = (route, state) => {

  const _CookieService = inject(SsrCookieService);
  const _AuthService = inject(AuthService)
  const _Router = inject(Router)
  let userInfo = _AuthService.getLoggedInUserInfo;

  // if there is hashId that means we are now in 2FA Mode user need to confirm login step
  // prevent it from entering any page and redirect him to login page also if he tried to reach any route
  if (_CookieService.get('hashId')) {
    _Router.navigate(['/auth/login']);
    return false;
  } else {
    return true;
  }
};
