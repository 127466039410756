import { Component} from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nav-footer-layout',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    FooterComponent,
    RouterOutlet
  ],
  templateUrl: './nav-footer-layout.component.html',
  styleUrl: './nav-footer-layout.component.scss',
})
export class NavFooterLayoutComponent {


}
