import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
@Component({
  selector: 'app-full-content-layout',
  templateUrl: './full-content-layout.component.html',
  styleUrl: './full-content-layout.component.scss',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    ToastModule
  ],
  providers: [MessageService]

})
export class FullContentLayoutComponent {

}
