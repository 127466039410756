import { inject } from '@angular/core';
import { CanActivateFn, Router} from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

export const emailGuard: CanActivateFn = (route, state) => {
  const _CookieService = inject(SsrCookieService)
  const _Router = inject(Router)

  // If User Verified Email Then Can Activate
  if (_CookieService.get('isEmailVerified') == "true") {
    return true;
  }

  // Not Verified Email
  if (_CookieService.get('isEmailVerified') == "false") {
    _Router.navigate(['/auth/confirm-email']);
    return false;
  }

  return true;

};
