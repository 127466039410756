import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ContactUsService } from '../../../modules/home/services/contact-us.service';
import { IContactUsResponse } from '../../../modules/home/interfaces/contact-us';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit, OnDestroy {

  public socialInfo!: IContactUsResponse[];
  public isLoading: boolean = false; // when geting social info
  public emailLink: string = 'mailto:sales@fastmoneytrade.com'; // in get Get in Touch section
  public currentYear = new Date().getFullYear(); // for copyright footer
  public phoneNumber = "201019228625";

  private _ContactService = inject(ContactUsService);
  private _Subscription = new Subscription();

  ngOnInit(): void {
    this.getSocialInfo();
  }

  getSocialInfo() {
    this.isLoading = true;

    this._Subscription.add(
      this._ContactService.getSocialInfo().subscribe({
        next: (res) => {
          this.socialInfo = res;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._Subscription.unsubscribe();
  }

}
