import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const _CookieService = inject(SsrCookieService)
  const _AuthService = inject(AuthService)

  if (_AuthService.getLoggedInUserInfo) { // user is logged in
    return true;
  } else { // not logged in
    return false;
  }

};
