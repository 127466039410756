<section class="full-content-layout">
  <div class="container h-100">
    <p-toast></p-toast>
    <p class="auth-nav pt-5 text-uppercase fs-4 fw-bold">
      <a routerLink="/home" class="logo">
        <img src="assets/images/fast_money_trade_logo-removebg-preview.png" alt="app logo" />
      </a>
    </p>

    <div class="row align-items-center justify-content-center parent">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
