import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-no-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ToastModule,
  ],
  templateUrl: './no-navbar.component.html',
  styleUrl: './no-navbar.component.scss',
  providers: [MessageService]
})
export class NoNavbarComponent {


}
